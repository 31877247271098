<header id="masthead" [class.shrink]="shrink" class="site-header" role="banner">
    <div class="site-header-main">
        <div class="site-branding">
            <a [routerLink]="'/'" class="custom-logo-link" rel="home" itemprop="url">
                <img 
                    alt="logo"
                    width="240"
                    height="45"
                    src="assets/images/logo.png"></a>
        </div><!-- .site-branding -->
    </div><!-- .site-header-main -->
</header>

<router-outlet></router-outlet>

<footer id="colophon" class="site-footer" role="contentinfo">


    <div class="site-info">
        <span class="site-title"><a href="https://www.imb-hannover.de/" rel="home">IMB Institut für Mittelstandsberatung
                GmbH</a></span>
    </div><!-- .site-info -->

    <nav class="secondary-navigation" role="navigation" aria-label="Footer Secondary Menu">
        <div class="menu-footer-container">
            <ul id="menu-footer-1" class="secondary-menu">
                <li class="menu-item"><a
                        [href]="globals.links.impressum" target="_blank">Impressum</a></li>
                <li class="menu-item"><a
                        [href]="globals.links.privacyPolicy"  target="_blank">Datenschutz</a></li>
                <li class="menu-item"><a
                        [href]="globals.links.termsOfUse" target="_blank">Nutzungsbedingungen</a></li>
                <li class="menu-item"><a
                        [href]="globals.links.declarationOfCancellation" target="_blank">Widerrufsbelehrung</a></li>
                <li class="menu-item"><a
                        [href]="globals.links.cookiePolicy" target="_blank">Cookie-Richtlinie</a></li>
            </ul>
        </div>
    </nav><!-- .main-navigation -->
</footer>

<app-error-modal></app-error-modal>
<app-spinner></app-spinner>
