import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpErrorResponse, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { ErrorNotificationService } from "./error-notification.service";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { HttpErrorCode as HttpError } from "../models/http-error-code";
import { ErrorNotification } from "../models/error-notification";

@Injectable()
export class ErrorInterceptorService implements HttpInterceptor {
  constructor(public errorNotificationService: ErrorNotificationService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(
        () => {},
        (exception) => {
          if (exception instanceof HttpErrorResponse) {
            switch (exception.status) {
              case HttpError.BadRequest:
                if (exception.error.message === "NotAvailable") {
                  this.showSurveyInactiveMessage();
                  break;
                }
              case HttpError.Unauthorized:
                this.showGenericMessage();
                break;

              case HttpError.NotFound:
                this.showGenericMessage();
                break;

              case HttpError.Timeout:
                this.showGenericMessage();
                break;

              case HttpError.Forbidden:
                this.showGenericMessage();
                break;

              case HttpError.InternalServerError:
                this.showGenericMessage();
                break;
            }
          }
        }
      )
    );
  }

  private showGenericMessage() {
    this.errorNotificationService.emitErrorNotification({
      title: "An error occurred",
      description: "Something went wrong. Please try again later.",
      confirmationCallback: () => {},
    } as ErrorNotification);
  }

  private showSurveyInactiveMessage() {
    this.errorNotificationService.emitErrorNotification({
      title: "Survey is not available.",
      description: "Survey does not exist or it is not in active state. The current entries will be deleted.",
      confirmationCallback: () => {
        window.location.href = "/";
      },
    } as ErrorNotification);
  }
}
