import { Injectable } from '@angular/core';
import { PersistedChapter } from '../models/persisted-chapter';
import { PersistedSurvey } from '../models/persisted-survey';
import { Subject, Observable } from 'rxjs';
import { ChapterValidationService } from './chapterValidation.service';
import { NavChapter } from '../models/nav-chapter';

const PERSISTED_SURVEY_STORAGE_KEY = 'PERSISTED_SURVEY_STORAGE';

@Injectable()
export class SurveyStorageService {

  constructor(private chapterValidationService: ChapterValidationService) { }
  private persistSurveyChapterSubject = new  Subject<any>();

  public get persistSurveyChapterObservable() {
    return this.persistSurveyChapterSubject as Observable<Subject<any>>;
  }

  public triggerPersistCurrentSurveyChapter() {
    this.persistSurveyChapterSubject.next(null);
  }

  public saveSurvey(surveyId: string, chapter: PersistedChapter) {
    const savedSurvey = this.getPersistedSurvey(surveyId);
    if (savedSurvey) {
      const chapterIndex = savedSurvey.chapters.findIndex(c => c.id === chapter.id);
      if (chapterIndex > -1) {
        savedSurvey.chapters.splice(chapterIndex, 1, chapter);
      } else {
        savedSurvey.chapters.push(chapter);
      }

      localStorage.setItem(this.getSurveyKey(surveyId), JSON.stringify(savedSurvey));
    } else {
      const abc: PersistedSurvey = {
        id: surveyId,
        chapters: [chapter]
      };

      localStorage.setItem(this.getSurveyKey(surveyId), JSON.stringify(abc));
    }
    this.chapterValidationService.navChapterPersistanceChanged.next({
      id: chapter.id,
      visited: chapter.visited,
      valid: chapter.valid
    } as NavChapter);
  }

  public updateSurvey(survey: PersistedSurvey){
    localStorage.setItem(this.getSurveyKey(survey.id), JSON.stringify(survey));
  }

  public getPersistedChapter(surveyId: string, chapterId: string): PersistedChapter {
    const survey: PersistedSurvey = this.getPersistedSurvey(surveyId);
    if (survey) {
      return survey.chapters.find(c => c.id === chapterId);
    }
    return null;
  }

  public isAllChaptersValid(surveyId: string) : boolean{
    let isAllValid = true;
    this.getPersistedSurvey(surveyId).chapters.forEach((element:PersistedChapter) => {
      if(!element.valid){
        isAllValid  = false;
      }
    });
    return isAllValid;
  }

  public getPersistedSurvey(surveyId: string): PersistedSurvey {
    const surveyJSON: string = localStorage.getItem(this.getSurveyKey(surveyId));
    if (surveyJSON) {
      return JSON.parse(surveyJSON);
    }
    return null;
  }

  private getSurveyKey(surveyId: string) {
    if (surveyId) {
      return `${PERSISTED_SURVEY_STORAGE_KEY}-${surveyId}`;
    } else {
      throw new Error(`Invalid survey id: ${surveyId}`);
    }
  }
}
