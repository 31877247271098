<div class="container">
  <div class="row justify-content">
      <h1>{{ chapter.displayedPosition }}. {{ chapter.name }}</h1>
      <button *ngIf="showPopulateSurvey" (click)="fillSurvey()" class="btn btn-light btn-fill-survey">Populate survey</button>
  </div>

  <div class="row questions">
    <div class="col-12">
      <form [formGroup]="questionsForm">
        <div class="question form-group" *ngFor="let question of chapter.questions">
          <p class="question-name" [ngClass]="{'invalid':(questionsForm.controls[question.id]?.dirty || questionsForm.controls[question.id]?.touched) && questionsForm.controls[question.id]?.invalid}">
            {{ question.position }}. {{ question.text }}
            <span *ngIf="(questionsForm.controls[question.id]?.dirty || questionsForm.controls[question.id]?.touched) && questionsForm.controls[question.id]?.invalid">*</span>
          </p>

          <div *ngIf="(question.description)">
            <p>{{question.description}}</p>
          </div>

          <div [ngSwitch]="question.type" class="answer-area">
            <!-- MultipleChoice -->
            <!-- required validation is not set -->
            <div *ngSwitchCase="questionType.MultipleChoice">
              <div *ngFor="let option of question.options; let i = index" [formGroupName]="question.id">
                <div class="input-container">
                  <input type="checkbox" formControlName="{{ option.id }}" value="{{ option.id }}"
                    id="{{ question.id + '-' + option.id }}" />
                  <label for="{{ question.id + '-' + option.id }}">{{
                  option.text
                }}</label>
                </div>
              </div>
            </div>

            <div *ngSwitchCase="questionType.SingleChoiceGrid">
              <div *ngFor="let subQuestion of question.questions; let i = index" [formGroupName]="question.id">
                <p class="question-name"
                  [ngClass]="{
                      'invalid': (
                        getFormGroup(questionsForm.controls[question.id])?.controls[subQuestion.id]?.dirty ||
                        getFormGroup(questionsForm.controls[question.id])?.controls[subQuestion.id]?.touched
                      ) &&
                      getFormGroup(questionsForm.controls[question.id])?.controls[subQuestion.id]?.invalid
                  }">
                  {{subQuestion.position}}. {{subQuestion.text}}
                  <span *ngIf="(
                      getFormGroup(questionsForm.controls[question.id])?.controls[subQuestion.id]?.dirty ||
                      getFormGroup(questionsForm.controls[question.id])?.controls[subQuestion.id]?.touched
                  ) &&
                  getFormGroup(questionsForm.controls[question.id])?.controls[subQuestion.id]?.invalid">*</span>
                </p>
                <div class="linear-scale-container subquestion-options" *ngFor="let option of subQuestion.options">
                  <input type="radio" formControlName="{{ subQuestion.id }}" value="{{ option.id }}"
                    id="{{ subQuestion.id + '-' + option.id }}" required />
                  <label for="{{ subQuestion.id + '-' + option.id }}">{{
                  option.text
                }}</label>
                </div>
              </div>
            </div>

            <!-- SingleChoice -->
            <div *ngSwitchCase="questionType.SingleChoice">
              <div class="input-container" *ngFor="let option of question.options">
                <input type="radio" formControlName="{{ question.id }}" value="{{ option.id }}"
                  id="{{ question.id + '-' + option.id }}" required />
                <label for="{{ question.id + '-' + option.id }}">{{
                option.text
              }}</label>
              </div>
            </div>

            <!-- LinearScale -->
            <div *ngSwitchCase="questionType.LinearScale">
                <div class="linear-scale-container" *ngFor="let option of question.options">
                  <input type="radio" formControlName="{{ question.id }}" value="{{ option.id }}"
                    id="{{ question.id + '-' + option.id }}" required />
                  <label for="{{ question.id + '-' + option.id }}">{{
                  option.text
                }}</label>
                </div>
            </div>

            <!-- DropDown -->
            <div *ngSwitchCase="questionType.DropDown">
              <select class="form-control" formControlName="{{ question.id }}" required>
                <option *ngFor="let option of question.options" [value]="option.id">
                  {{ option.text }}</option>
              </select>
            </div>

            <!-- ShortText -->
            <div *ngSwitchDefault>
              <textarea formControlName="{{ question.id }}" class="textarea" required></textarea>
            </div>
          </div>
          <hr/>
        </div>
      </form>
    </div>
  </div>

  <div class="footer">
    <div class="col-12 float-right">
      <button [disabled]="isFirstChapter" (click)="previousChapter()" class="btn btn-light">
          Zurück
      </button>

      <button (click)="nextChapter()" class="btn btn-light next-button">
          Nächste
      </button>
    </div>
  </div>
</div>
