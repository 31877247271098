<div class="home-container">
  <div class="home-sub-container">
    <div class="home">
      <div class="pricing-tier-container" *ngFor="let survey of availableSurveys$ | async">
        <div class="content-container">
          <h3 class="pricing-tier-title">{{ survey.name }}</h3>
          <p class="price">{{ survey.price === 0 ? 'KOSTENLOS' : (survey.price | currency:'EUR') }}</p>
        </div>
        <div class="content-container">
          <p class="description">{{survey.description}}</p>
        </div>
        <div class="content-container">
          <p class="time-completion">Investieren Sie etwa {{survey.completionTime}}.</p>
        </div>
        <div class="content-container">
          <button [routerLink]="[survey.id]" class="choose-plan-btn">{{ survey.price === 0 ? 'KOSTENLOSER' : survey.name.split(' ')[0] }} Check</button>
        </div>
      </div>
    </div>

    <div class="scroll-button-container">
      <button class="scroll-button" (click)="scrollToTable()">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1"/>
        </svg>
      </button>
    </div>
  </div>

  <div class="features-table-container" id="features-table-container">
    <table class="features-table" id="features-table">
      <thead>
        <tr>
          <th>Themenbereiche</th>
          <th *ngFor="let survey of availableSurveys$ | async">{{ survey.name }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let chapter of dynamicChapters$ | async">
          <td>{{ chapter.name }}</td>
          <td *ngFor="let survey of availableSurveys$ | async">
            <ng-container *ngIf="chapter[survey.id]">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="green" class="bi bi-check-lg checkmark-down" viewBox="0 0 16 16">
                <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z"/>
              </svg>
            </ng-container>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
