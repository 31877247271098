import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ChapterComponent } from '../components/chapter/chapter.component';
import { SurveyStorageService } from './survey-storage.service';
import { SurveyService } from './survey.service';

@Injectable()
export class ChapterRouteService  {
  constructor(private chapterStorageService: SurveyStorageService, private surveyService: SurveyService) {}

  canDeactivate(
    component: ChapterComponent,
    activeroute: ActivatedRouteSnapshot,
    routestate: RouterStateSnapshot,
    routestateNext: RouterStateSnapshot
  ): boolean {
    // this is needed because we use chapter component even if chapterId is not suplied,
    // and redirect to first chapter than, and persistance is not needed for '' chapterId.
    if (activeroute.params.chapterId) {
      this.chapterStorageService.triggerPersistCurrentSurveyChapter();
      // In case of subscribe in chapter.component become aysnc, this lines should be used.
      // const pom = this.chapterStorageService.SurveyChapterPersistedObservable.pipe(first());
    }
    // return pom;
    return true;
  }
}
