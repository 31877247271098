import { HostListener, Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import { Globals } from './services/globals.service';
import { ErrorNotificationService } from './services/error-notification.service';
import { ErrorNotification } from './models/error-notification';
import { Subscription } from 'rxjs';
import { ErrorModalComponent } from './components/error-modal/error-modal.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'app';
  shrink: boolean = true;

  errorSubscription: Subscription;
  modalContent = {title: '', description: ''} as ErrorNotification;

  @ViewChild(ErrorModalComponent)
  public errorModal: ErrorModalComponent;

  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    this.shrink = window.scrollY > 100 ? false : true;
  }

  @HostListener('window:offline', ['$event'])
  onOffline(event) {
   console.log('error');
   this.errorNotificationService.emitErrorNotification({
     title: 'An error occurred',
     description: 'Connection lost.',
   } as ErrorNotification);
  }

  constructor(public globals: Globals, private errorNotificationService: ErrorNotificationService) {}

  ngOnInit() {
    const cc = window as any;
    cc.cookieconsent.initialise({
      palette: {
        popup: {
          background: "#164969"
        },
        button: {
          background: "#ffe000",
          text: "#164969"
        }
      },
      theme: "classic",
      content: {
        message: `<span> Für unser Online-Angebot des Krisen-Check kommen – wie auf vielen Webseiten – Cookies zum Einsatz. Cookies helfen uns, das Online-Angebot zu verbessern sowie Ihnen einen besseren und noch mehr auf Sie zugeschnittenen Service anbieten zu können. Unsere <a href="${this.globals.links.cookiePolicy}" target="_blank"> Cookie-Richtlinie </a> finden Sie hier. </span>`,
        dismiss: "Cookies akzeptieren",
        link: ""
      }
    });

    this.initErrorNoticiationHandling();
  }

  initErrorNoticiationHandling() {
    this.errorSubscription = this.errorNotificationService.errorNotifications.subscribe(
      (errorNotification: ErrorNotification) => {
        this.modalContent = errorNotification;
        this.openErrorModal(errorNotification);
      }
    );
  }

  openErrorModal(errorNotification: ErrorNotification) {
    this.errorModal.show(errorNotification);
  }

  ngOnDestroy() {
    this.errorSubscription.unsubscribe();
  }
}
