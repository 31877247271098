import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  private readonly STORAGE_PREFIX = 'PERSISTED_SURVEY_STORAGE-';

  constructor() { }

  public clearStorage(id: string): void {
    localStorage.removeItem(this.STORAGE_PREFIX + id);
  }
}
