<div id="errorModal" class="modal" #errorModal>
  <!-- Modal content -->
  <div class="modal-content">
    <div class="modal-content-header">
      <h3 class="modal-text">{{errorNotification.title}}</h3>
    </div>
    <hr>
    <p class="modal-text modal-text-content">{{errorNotification.description}}</p>
    <div class="modal-content-footer">
      <button class="btn" (click)="onConfirm()">OK</button>
    </div>
  </div>
</div>
