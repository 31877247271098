<h1>
    Bitte geben Sie nachfolgend Ihre E-Mail Adresse und den ggf. vorhandenen Gutscheincode ein.
</h1>
<div class="paypal-btn-container">
  <div [formGroup]="paymentForm">
    <div class="question form-group">
      <p class="question-name"
        [ngClass]="{'invalid':(paymentForm.controls.customerEmail.dirty || paymentForm.controls.customerEmail?.touched) && paymentForm.controls.customerEmail?.invalid}">
        Email*
      </p>
      <input type="email" formControlName="customerEmail">
    </div>
    <div class="question form-group" *ngIf="!isSurveyFree">
      <p class="question-name"> Rabatt-Gutschein-Code </p>
      <input type="text" formControlName="discountCode">
    </div>
    <div class="question form-group">
      <p class="question-name display-flex"
      [ngClass]="{'invalid':(paymentForm.controls.termsOfUse.dirty || paymentForm.controls.termsOfUse?.touched) && paymentForm.controls.termsOfUse?.invalid}">
        <input type="checkbox" class="checkbox-input" formControlName="termsOfUse"> &nbsp;
        Ich habe die &nbsp;
        <a [href]="globals.links.termsOfUse" target="_blank">Nutzungsbedingungen </a> &nbsp; gelesen und stimme diesen zu.*
      </p>
    </div>

    <div class="question form-group">
      <p class="question-name display-flex"
      [ngClass]="{'invalid':(paymentForm.controls.privacyPolicy.dirty || paymentForm.controls.privacyPolicy?.touched) && paymentForm.controls.privacyPolicy?.invalid}">
        <input type="checkbox" class="checkbox-input" formControlName="privacyPolicy"> &nbsp;
        Ich habe die &nbsp;
        <a [href]="globals.links.privacyPolicy" target="_blank"> Datenschutzerklärung </a> &nbsp; gelesen.*
      </p>
    </div>

    <div class="question form-group">
      <p class="question-name display-flex"
      [ngClass]="{'invalid':(paymentForm.controls.declarationCancelation.dirty || paymentForm.controls.declarationCancelation?.touched) && paymentForm.controls.declarationCancelation?.invalid}">
        <input type="checkbox" class="checkbox-input" formControlName="declarationCancelation"> &nbsp;
        Ich habe die &nbsp;
        <a [href]="globals.links.declarationOfCancellation" target="_blank"> Widerrufsbelehrung </a> &nbsp; gelesen.*
      </p>
    </div>

    <div class="question form-group" *ngIf="!isSurveyFree; else freeSurveyButton" [class.disabled]="this.paymentForm.invalid">
      <ngx-paypal [config]="payPalConfig"></ngx-paypal>
    </div>
  </div>

  <div *ngIf="errorOccurred" class="error-message">
    <h2>{{errorMessage}}</h2>
  </div>
</div>

<ng-template #freeSurveyButton>
  <div class="question form-group">
    <button (click)="createFreeOrderOnServer()" type="button" class="submit-survey-btn" [disabled]="paymentForm.invalid">Umfrage einreichen</button>
  </div>
</ng-template>
