import { Component, ViewChild } from '@angular/core';
import { ErrorNotification } from 'src/app/models/error-notification';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.css']
})
export class ErrorModalComponent {

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public errorNotification: ErrorNotification = {title: '', description: '', confirmationCallback: () => {}};

  @ViewChild('errorModal')
  errorModal: any;

  public get showDescription(): boolean {
    return this.errorNotification.description != null && this.errorNotification.description !== undefined;
  }

  public show(errorNotification: ErrorNotification) {
    this.errorNotification = errorNotification;
    this.errorModal.nativeElement.setAttribute('style', 'display:block');
  }

  public onConfirm() {
    this.errorModal.nativeElement.setAttribute('style', 'display:none');
    this.errorNotification.confirmationCallback();
  }
}
