import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SurveyStorageService } from 'src/app/services/survey-storage.service';
import { SurveyService } from 'src/app/services/survey.service';
import { Survey } from 'src/app/models/survey';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-finish',
  templateUrl: './finish.component.html',
  styleUrls: ['./finish.component.css']
})
export class FinishComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private surveyStorageService: SurveyStorageService,
    private surveyService:SurveyService,
    private spinnerService: SpinnerService) { }

  private surveyId: string;
  private survey: Survey;
  public isAllChaptersValid: boolean = false;
  public isSurveyFree: boolean = false;
  ngOnInit() {
    this.surveyId = this.route.snapshot.parent.params.surveyId;
    this.checkSurvey();
    window.scrollTo(0,0);

    // in case if user reloads the current URL (/finish route) we need to stop the spinner
    this.spinnerService.hide();
  }

  private checkSurvey(){
    if(this.surveyStorageService.isAllChaptersValid(this.surveyId)){
      this.surveyService.getSurvey(this.surveyId).subscribe(
        (data:Survey) => {
          this.isAllChaptersValid = true;
          if(data.price === 0){
            this.isSurveyFree = true;
          }
        }
      )
    }
  }

}
