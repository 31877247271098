<div class="host">
  <div class="anonymize-container">
    <div *ngIf='!isAnonymizationFinished;else anonymizationMessage'>
      <h1 class="text">Anonymisierung der Daten</h1>
      <div [formGroup]="form">
        <div class="question form-group">
          <p class="question-name text"
            [ngClass]="{'invalid':(form.controls.data.dirty || form.controls.data?.touched) && form.controls.data?.invalid}">
            Datenschlüssel (per Email erhalten)*
          </p>
          <input class="data-input" type="text" formControlName="data">
        </div>
      </div>
      <button class="btn" [disabled]="form.controls.data.invalid" (click)='openModal()'>Bitte anonymisieren Sie meine Daten</button>
    </div>

    <ng-template #anonymizationMessage>
      <h1 class="text">Ihre Daten wurden erfolgreich anonymisiert!</h1>
      <button class="btn" (click)="goToHome()">Zurück zur Homepage</button>
    </ng-template>
  </div>
</div>

<div id="myModal" class="modal" #myModal (click)="closeModal()">
  <!-- Modal content -->
  <div class="modal-content" (click)="$event.stopPropagation()">
    <div class="modal-content-header">
      <h1 class="modal-text">Bestätigung</h1>
      <span class="close-button" (click)="closeModal()">&times;</span>
    </div>
    <hr>
    <p class="modal-text modal-text--content ">Sind Sie sicher, dass Ihre Daten anonymisiert werden sollen?</p>
    <hr>
    <div class="modal-content-footer">
      <button (click)="anonymize()" class="btn">Ja</button>
      <button (click)="closeModal()" class="btn">Nein</button>
    </div>
  </div>
</div>
