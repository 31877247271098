import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { ChapterComponent } from './components/chapter/chapter.component';
import { SurveyService } from './services/survey.service';
import { SurveyStorageService } from './services/survey-storage.service';
import { SurveyComponent } from './components/survey/survey.component';
import { NavMenuComponent } from './components/nav-menu/nav-menu.component';
import { ChapterRouteService } from './services/chapterRoute.service';
import { ChapterValidationService } from './services/chapterValidation.service';
import { NgxPayPalModule } from 'ngx-paypal';
import { PaymentComponent } from './components/payment/payment.component';
import { ThankYouComponent } from './components/thank-you/thank-you.component';
import { FinishComponent } from './components/finish/finish.component';
import { ReportComponent } from './components/report/report.component';
import { AnonymizeComponent } from './components/anonymize/anonymize.component';
import { Globals } from './services/globals.service';
import { ErrorInterceptorService } from './services/error-interceptor.service';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { ErrorModalComponent } from './components/error-modal/error-modal.component';

@NgModule({ declarations: [
        AppComponent,
        NavMenuComponent,
        HomeComponent,
        ChapterComponent,
        SurveyComponent,
        PaymentComponent,
        ThankYouComponent,
        FinishComponent,
        ReportComponent,
        AnonymizeComponent,
        SpinnerComponent,
        ErrorModalComponent,
    ],
    bootstrap: [AppComponent], imports: [BrowserModule.withServerTransition({ appId: "ng-cli-universal" }),
        ReactiveFormsModule,
        FormsModule,
        NgbModule,
        NgxPayPalModule,
        RouterModule.forRoot([
            { path: "", component: HomeComponent, pathMatch: "full" },
            { path: "payment/:surveyId", component: PaymentComponent },
            { path: "thank-you", component: ThankYouComponent },
            { path: "anonymize/:key", component: AnonymizeComponent },
            { path: "h2o", component: ReportComponent },
            {
                path: ":surveyId",
                component: SurveyComponent,
                children: [
                    {
                        path: "finish",
                        component: FinishComponent,
                    },
                    {
                        path: ":chapterId",
                        component: ChapterComponent,
                        canDeactivate: [ChapterRouteService],
                    },
                    {
                        path: "",
                        component: ChapterComponent,
                        canDeactivate: [ChapterRouteService],
                    },
                ],
            },
        ], {})], providers: [
        SurveyService,
        SurveyStorageService,
        ChapterRouteService,
        ChapterValidationService,
        Globals,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptorService,
            multi: true,
        },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {}
