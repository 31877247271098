<div class="main-nav">
  <div class="navbar navbar-grey navbar-inverse">
    <div class="navbar-header">
      <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse"
        [attr.aria-expanded]="isExpanded" (click)="toggle()">
        <span class="sr-only">Toggle navigation</span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
      <a class="navbar-brand">{{surveyName}}</a>
    </div>
    <div class="clearfix"></div>
    <div class="navbar-collapse collapse" [ngClass]="{ in: isExpanded }">
      <ul class="nav navbar-nav">
        <li *ngFor="let chapter of chapters; let i = index" [routerLinkActive]="['link-active']"
        [routerLinkActiveOptions]="{ exact: true }">
          <a [routerLink]="[chapter.id]" (click)="collapse()">
            {{ i + 1 }}.{{ chapter.name }}
            <span *ngIf="chapter.visited && chapter.valid" class="valid-chapter">&#10003;</span>
            <span *ngIf="chapter.visited && !chapter.valid" class="invalid-chapter">
              *
            </span>
          </a>
        </li>
        <li [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }">
          <a [routerLink]="['finish']" (click)="collapse()">
            Ende
            <span *ngIf="isAllValid" class="valid-chapter">&#10003;</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
