import { Component, OnInit } from '@angular/core';
import { SurveyService } from '../../services/survey.service';
import { ChapterRow, SurveyInfo } from 'src/app/models/survey-info';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map, shareReplay } from 'rxjs/operators';
import { ChapterBasicInfo } from 'src/app/models/chapter-basic-info';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  availableSurveys$: Observable<SurveyInfo[]>;
  dynamicChapters$: Observable<ChapterRow[]>;

  constructor(private surveyService: SurveyService, private spinnerService: SpinnerService) {}

  ngOnInit(): void {
    this.spinnerService.show("Wird geladen...");

    this.availableSurveys$ = this.surveyService.getAllSurveyInfo().pipe(
      map((surveys) => surveys.sort((a, b) => a.price - b.price)),
      shareReplay(1),
      catchError((error) => {
        console.error("Error fetching surveys:", error);
        return throwError(() => new Error("Failed to load surveys"));
      }),
      finalize(() => this.spinnerService.hide())
    );

    this.dynamicChapters$ = this.availableSurveys$.pipe(
      map((surveys) => this.generateChapterRows(surveys))
    );
  }

  scrollToTable(): void {
    const table = document.getElementById('features-table-container');
    if (table) {
      table.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  private generateChapterRows(surveys: SurveyInfo[]): ChapterRow[] {
    const uniqueChapters = this.getUniqueSortedChapters(surveys);

    return uniqueChapters.map((chapter) =>
      this.mapChapterToRow(chapter, surveys)
    );
  }

  private getUniqueSortedChapters(surveys: SurveyInfo[]): ChapterBasicInfo[] {
    const allChapters = surveys.flatMap((survey) => survey.chapterBasicInfos);

    const uniqueChaptersMap = new Map<string, ChapterBasicInfo>();
    allChapters.forEach((chapter) => {
      if (!uniqueChaptersMap.has(chapter.name)) {
        uniqueChaptersMap.set(chapter.name, chapter);
      }
    });

    return Array.from(uniqueChaptersMap.values()).sort(
      (a, b) => a.position - b.position
    );
  }

  private mapChapterToRow(chapter: ChapterBasicInfo, surveys: SurveyInfo[]): ChapterRow {
    const row: ChapterRow = {
      id: chapter.id,
      name: chapter.name,
    };

    surveys.forEach((survey) => {
      row[survey.id] = survey.chapterBasicInfos.some((c) => c.name === chapter.name);
    });

    return row;
  }
}
