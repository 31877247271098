import { Question } from "./question";

export class Chapter {
  id: string;
  name: string;
  position: number;
  displayedPosition: number;
  questions: Question[];
  constructor() {
    this.questions = new Array<Question>();
  }
}
