import {
  Component,
  OnInit,
  ChangeDetectorRef,
  AfterViewChecked,
  OnDestroy,
  ViewChild,
} from "@angular/core";
import { SurveyService } from "../../services/survey.service";
import { Chapter } from "../../models/chapter";
import { ActivatedRoute, Router } from "@angular/router";
import {
  UntypedFormGroup,
  UntypedFormControl,
  ValidatorFn,
  ValidationErrors,
  AbstractControl,
} from "@angular/forms";
import { QuestionType } from "../../models/question-type";
import { SurveyStorageService } from "src/app/services/survey-storage.service";
import { PersistedChapter } from "src/app/models/persisted-chapter";
import { QuestionAnswer } from "src/app/models/question-answer";
import { Survey } from "src/app/models/survey";
import { Subscription } from "rxjs";
import { ErrorModalComponent } from "../error-modal/error-modal.component";
import { SpinnerService } from 'src/app/services/spinner.service';
import { debounceTime } from 'rxjs/operators';
import { PersistedSurvey } from "../../models/persisted-survey";

@Component({
  selector: "app-chapter",
  templateUrl: "./chapter.component.html",
  styleUrls: ["./chapter.component.css"],
})
export class ChapterComponent
  implements OnInit, AfterViewChecked, OnDestroy {
  chapter: Chapter;
  persistedChapter: PersistedChapter;
  surveyId: string;
  questionType = QuestionType;
  questionsForm: UntypedFormGroup;
  public isFirstChapter: boolean;
  private isLastChapter: boolean;
  private isAllValid: boolean;
  private persistSurveyChapterSubscription: Subscription;
  showPopulateSurvey: boolean;

  @ViewChild(ErrorModalComponent)
  public errorModal: ErrorModalComponent;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private surveyService: SurveyService,
    private chapterStorageService: SurveyStorageService,
    private cd: ChangeDetectorRef,
    private spinnerService: SpinnerService,
    private surveyStorageService: SurveyStorageService,
  ) {
    this.chapter = new Chapter();
    this.questionsForm = new UntypedFormGroup({});
  }

  ngAfterViewChecked() {
    this.cd.detectChanges();
  }

  ngOnInit() {
    this.showPopulateSurvey = (((document.getElementById("isDebugMode")) as any).value).toLowerCase() == "true"; 

    this.persistSurveyChapterSubscription = this.chapterStorageService.persistSurveyChapterObservable.subscribe(
      (_) => {
        this.persistCurrentChapter();
        // TODO: if persistCurrentChapter() was async this, event should be triggered
        // this.chapterStorageService.triggerCurrentSurveyChapterPersisted(true);
      }
    );

    this.route.parent.params.subscribe((parentParams) => {
      this.surveyId = parentParams.surveyId;

      // on chapter change check if survey is active.
      // if not active, an alert will be displayed (an interceptor will be called)
      // for locale storage deletion is responsible SurveyComponent
      this.route.params.pipe(debounceTime(5000)).subscribe(params => {
        if (params.chapterId) {
          this.surveyService.checkIfExitsAndIsActive(this.surveyId).subscribe();
        }
      });

      this.route.params.subscribe((params) => {
        if (params.chapterId) {
          this.surveyService
            .getChapter(this.surveyId, params.chapterId)
            .subscribe((c) => {
              this.chapter = c;
              this.checkFirstLastChapters(c);
              this.loadChapterFromLocalStorage();
              this.updatedFormGroupWithDynamiclyLoadedQuestions();
              if (this.persistedChapter.visited) {
                this.questionsForm.markAllAsTouched();
              }
            });
        } else {
          this.surveyService
            .getfirstChapterId(parentParams.surveyId)
            .subscribe(
              (res) => this.router.navigate([parentParams.surveyId, res]),
              () => this.router.navigate(['/'])
            );
        }
      });
    });
  }

  fillSurvey(){
    this.surveyService.getMockSurvey(this.surveyId)
        .subscribe((data:PersistedSurvey) => 
          {
            this.surveyStorageService.updateSurvey(data);
            window.location.reload();  
          });
  }

  checkFirstLastChapters(c: Chapter) {
    window.scrollTo(0, 0);
    this.surveyService.getSurvey(this.surveyId).subscribe((data: Survey) => {
      this.spinnerService.hide();
      this.isFirstChapter = data.chapters[0].position == c.position;
      this.isLastChapter =
        data.chapters[data.chapters.length - 1].position == c.position;
    });
  }

  nextChapter() {
    this.persistCurrentChapter();
    if (this.isLastChapter) {
      this.router.navigate([this.surveyId, "finish"]);
    } else {
      this.surveyService
        .getNextChapter(this.surveyId, this.chapter)
        .subscribe((nextChapter) => {
          this.router.navigate([this.surveyId, nextChapter.id]);
        });
    }
  }

  previousChapter() {
    this.persistCurrentChapter();
    this.surveyService
      .getPreviousChapter(this.surveyId, this.chapter)
      .subscribe((previousChapter) => {
        this.router.navigate([this.surveyId, previousChapter.id]);
      });
  }

  private loadChapterFromLocalStorage(): void {
    this.persistedChapter = this.chapterStorageService.getPersistedChapter(
      this.surveyId,
      this.chapter.id
    );
  }

  private updatedFormGroupWithDynamiclyLoadedQuestions() {
    this.questionsForm = new UntypedFormGroup({});

    this.chapter.questions.forEach((question) => {
      if (question.type === QuestionType.SingleChoiceGrid) {
        const formGroup: UntypedFormGroup = new UntypedFormGroup({});
        question.questions.map((subQuestion) => {
          const persistedAnswers = this.getPersistedAnswers(subQuestion.id);
          const formControl = new UntypedFormControl(
            persistedAnswers ? persistedAnswers[0] : null
          );
          formGroup.addControl(subQuestion.id, formControl);
        });
        this.questionsForm.addControl(question.id, formGroup);
      } else if (question.type === QuestionType.MultipleChoice) {
        const persistedAnswers = this.getPersistedAnswers(question.id);
        const formGroup: UntypedFormGroup = new UntypedFormGroup({});
        let firstOptionId = null;
        question.options.map((option) => {
          const controlValue = persistedAnswers.find((a) => a === option.id)
            ? true
            : false;
          const formControl = new UntypedFormControl(controlValue, null);
          formGroup.addControl(option.id, formControl);

          if (!firstOptionId) {
            formControl.valueChanges.subscribe((val) => {
              if (val) {
                Object.keys(formGroup.controls).forEach((key) => {
                  if (key !== option.id) {
                    formGroup.controls[key].setValue(false);
                  }
                });
              }
            });
            firstOptionId = option.id;
          } else {
            formControl.valueChanges.subscribe((val) => {
              if (val) {
                formGroup.controls[firstOptionId].setValue(false);
              }
            });
          }
        });
        formGroup.setValidators(this.multipleChoiceValidator());
        this.questionsForm.addControl(question.id, formGroup);
      } else {
        const persistedAnswers = this.getPersistedAnswers(question.id);
        const formControl = new UntypedFormControl(
          persistedAnswers ? persistedAnswers[0] : null
        );
        this.questionsForm.addControl(question.id, formControl);
      }
    });
  }

  private multipleChoiceValidator(): ValidatorFn {
    return (group: UntypedFormGroup): ValidationErrors => {
      let numberOfSelected = 0;
      Object.keys(group.controls).forEach((key) => {
        if (group.controls[key].value) {
          ++numberOfSelected;
          return;
        }
      });
      if (numberOfSelected !== 0) {
        return null;
      } else {
        return { required: true };
      }
      return;
    };
  }

  private persistCurrentChapter() {
    const questionAnswers: QuestionAnswer[] = [];
    Object.keys(this.questionsForm.controls).forEach((controlKey) => {
      const questionType = this.chapter.questions.find(
        (q) => q.id === controlKey
      ).type;
      const formControl = this.questionsForm.controls[controlKey];
      if (questionType === QuestionType.MultipleChoice) {
        questionAnswers.push({
          questionId: controlKey,
          answers: this.getMultipleAnswers(formControl.value),
        });
      } else if (questionType === QuestionType.SingleChoiceGrid) {
        const subControlFormGroup = formControl as UntypedFormGroup;
        Object.keys(subControlFormGroup.controls).forEach((subControlKey) => {
          questionAnswers.push({
            questionId: subControlKey,
            answers: [subControlFormGroup.controls[subControlKey].value],
          });
        });
      } else {
        questionAnswers.push({
          questionId: controlKey,
          answers: [formControl.value],
        });
      }
    });
    const chapter: PersistedChapter = {
      id: this.chapter.id,
      questionAnswersCollection: questionAnswers,
      visited: true,
      valid: this.questionsForm.valid,
    };
    this.chapterStorageService.saveSurvey(this.surveyId, chapter);
    this.isAllValid = this.chapterStorageService.isAllChaptersValid(
      this.surveyId
    );
  }

  private getMultipleAnswers(formControlValue: any): string[] {
    const answers = [];
    for (const [key, value] of Object.entries(formControlValue)) {
      if (value === true) {
        answers.push(key);
      }
    }
    return answers;
  }

  private getPersistedAnswers(questionId: string): string[] | null {
    if (this.persistedChapter.visited) {
      const questionAnswer = this.persistedChapter.questionAnswersCollection.find(
        (a) => a.questionId === questionId
      );
      return questionAnswer ? questionAnswer.answers : null;
    }
    return [];
  }

  public getFormGroup(control: AbstractControl | null): UntypedFormGroup | null {
    return control instanceof UntypedFormGroup ? control : null;
  }

  ngOnDestroy() {
    this.persistSurveyChapterSubscription.unsubscribe();
  }
}
