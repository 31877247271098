<div class="reports">
  <div class="container">
    <table class="table">
      <tbody>
        <tr *ngFor="let report of reports | async">
          <td>{{ report.year }}</td>
          <td>{{ report.orderCount }}</td>
          <td>{{ report.sum }}</td>
          <td>{{ report.discountSum }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
